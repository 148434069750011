@keyframes zoom-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

/* //////////////////// */

@keyframes zoom-out {
  0% {
    opacity: 0;
    transform: scale(4);
    filter: brightness(80%);
  }
  25% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

/* //////////////////// */

@keyframes fade-in-linear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in-flash {
  0% {
    opacity: 0;
    filter: saturate(50%) brightness(200%);
  }
  50% {
    opacity: 1;
    filter: saturate(50%) brightness(200%);
  }
}

@keyframes fade-in-flicker {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: .5;
  }
  30% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  45% {
    opacity: .75;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  65% {
    opacity: .5;
  }
  70% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  85% {
    opacity: .75;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

/* //////////////////// */

@keyframes bounce {
  0% {
    transform: scale(0, 0);
  }
  25% {
    transform: scale(.5, 2);
  }
  50% {
    transform: scale(2, .5);
  }
  75% {
    transform: scale(.5, 2);
  }
  100% {
    transform: scale(1, 1);
  }
}

/* //////////////////// */

@keyframes skew-cw {
  0% {
    transform: skew(-45deg, -45deg);
  }
  100% {
    transform: skew(0, 0);
  }
}

@keyframes skew-ccw {
  0% {
    transform: skew(45deg, 45deg);
  }
  100% {
    transform: skew(0, 0);
  }
}

/* //////////////////// */

@keyframes pop-center {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
    filter: brightness(80%);
  }
  75% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pop-top-left {
  0% {
    transform-origin: top left;
    transform: scale(0);
  }
  50% {
    transform: scale(1);
    filter: brightness(80%);
  }
  75% {
    transform: scale(1.5);
  }
  100% {
    transform-origin: top left;
    transform: scale(1);
  }
}

@keyframes pop-top-right {
  0% {
    transform-origin: top right;
    transform: scale(0);
  }
  50% {
    transform: scale(1);
    filter: brightness(80%);
  }
  75% {
    transform: scale(1.5);
  }
  100% {
    transform-origin: top right;
    transform: scale(1);
  }
}

@keyframes pop-bottom-right {
  0% {
    transform-origin: bottom right;
    transform: scale(0);
  }
  50% {
    transform: scale(1);
    filter: brightness(80%);
  }
  75% {
    transform: scale(1.5);
  }
  100% {
    transform-origin: bottom right;
    transform: scale(1);
  }
}

@keyframes pop-bottom-left {
  0% {
    transform-origin: bottom left;
    transform: scale(0);
  }
  50% {
    transform: scale(1);
    filter: brightness(80%);
  }
  75% {
    transform: scale(1.5);
  }
  100% {
    transform-origin: bottom left;
    transform: scale(1);
  }
}

/* //////////////////// */

@keyframes pull-vertical {
  0% {
    transform: scale(0, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes pull-horizontal {
  0% {
    transform: scale(1, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes pull-down {
  0% {
    transform-origin: top;
    transform: scaleY(0);
  }
  100% {
    transform-origin: top;
    transform: scaleY(1);
  }
}

@keyframes pull-up {
  0% {
    transform-origin: bottom;
    transform: scaleY(0);
  }
  100% {
    transform-origin: bottom;
    transform: scaleY(1);
  }
}

@keyframes pull-right {
  0% {
    transform-origin: left;
    transform: scaleX(0);
  }
  100% {
    transform-origin: left;
    transform: scaleX(1);
  }
}

@keyframes pull-left {
  0% {
    transform-origin: right;
    transform: scaleX(0);
  }
  100% {
    transform-origin: right;
    transform: scaleX(1);
  }
}

/* //////////////////// */

@keyframes shoot-up {
  0% {
    opacity: 0;
    transform: translateY(800%) scale(1);
  }
  25% {
    opacity: 1;
  }
  66% {
    transform: translateY(-400%) scale(1.5);
  }
  100% {
    transform: translateY(0) scale(1);
  }
}

/* //////////////////// */

@keyframes shake-down {
  0% {
    opacity: 0;
    transform: translate(-100%, -800%) rotate(20deg);
  }
  25% {
    opacity: 1;
    transform: translate(100%, -600%) rotate(-20deg);
  }
  50% {
    transform: translate(-50%, -400%) rotate(10deg);
  }
  75% {
    transform: translate(50%, -200%) rotate(-10deg);
  }
  100% {
    transform: translate(0, 0) rotate(0);
  }
}

/* //////////////////// */

@keyframes drip {
  0% {
    transform-origin: bottom;
    opacity: 0;
    transform: scale(1, 4) translateY(-400%);
  }
  25% {
    opacity: 1;
  }
  75% {
    transform: scale(1, 0) translateY(0);
  }
  100% {
    transform-origin: bottom;
    transform: scaleX(1, 1) translateY(0);
  }
}

/* //////////////////// */

@keyframes elastic-right {
  0% {
    transform-origin: left;
    opacity: 0;
    transform: scaleX(1) translateX(400%);
  }
  25% {
    opacity: 1;
  }
  50% {
    transform: scaleX(4) translateX(0);
  }
  100% {
    transform-origin: left;
    transform: scaleX(1) translateX(0);
  }
}

@keyframes elastic-left {
  0% {
    transform-origin: right;
    opacity: 0;
    transform: scaleX(1) translateX(-400%);
  }
  25% {
    opacity: 1;
  }
  50% {
    transform: scaleX(4) translateX(0);
  }
  100% {
    transform-origin: right;
    transform: scaleX(1) translateX(0);
  }
}

@keyframes elastic-up {
  0% {
    transform-origin: bottom;
    opacity: 0;
    transform: scaleY(1) translateY(-400%);
  }
  25% {
    opacity: 1;
  }
  50% {
    transform: scaleY(4) translateY(0);
  }
  100% {
    transform-origin: bottom;
    transform: scaleY(1) translateY(0);
  }
}

@keyframes elastic-down {
  0% {
    transform-origin: top;
    opacity: 0;
    transform: scaleY(1) translateY(400%);
  }
  25% {
    opacity: 1;
  }
  50% {
    transform: scaleY(4) translateY(0);
  }
  100% {
    transform-origin: top;
    transform: scaleY(1) translateY(0);
  }
}


/* //////////////////// */

@keyframes roll-down-left {
  0% {
    opacity: 0;
    transform: translate(-400%, -400%) rotate(-90deg);
  }
  25% {
    opacity: 1;
  }
  100% {
    transform: translate(0) rotate(0);
  }
}

@keyframes roll-down-right {
  0% {
    opacity: 0;
    transform: translate(400%, -400%) rotate(90deg);
  }
  25% {
    opacity: 1;
  }
  100% {
    transform: translate(0) rotate(0);
  }
}

@keyframes roll-up-left {
  0% {
    opacity: 0;
    transform: translate(-400%, 400%) rotate(-90deg);
  }
  25% {
    opacity: 1;
  }
  100% {
    transform: translate(0) rotate(0);
  }
}

@keyframes roll-up-right {
  0% {
    opacity: 0;
    transform: translate(400%, 400%) rotate(90deg);
  }
  25% {
    opacity: 1;
  }
  100% {
    transform: translate(0) rotate(0);
  }
}

/* //////////////////// */

@keyframes hop-right {
  0% {
    opacity: 0;
    transform: translate(-400%, 0) scale(.2);
  }
  25% {
    opacity: 1;
    transform: translate(-300%, -200%) scale(.4);
  }
  50% {
    transform: translate(-200%, 0) scale(.6);
  }
  75% {
    transform: translate(-100%, -100%) scale(.8);
  }
  100% {
    transform: translate(0, 0) scale(1);
  }
}

@keyframes hop-left {
  0% {
    opacity: 0;
    transform: translate(400%, 0) scale(.2);
  }
  25% {
    opacity: 1;
    transform: translate(300%, -200%) scale(.4);
  }
  50% {
    transform: translate(200%, 0) scale(.6);
  }
  75% {
    transform: translate(100%, -100%) scale(.8);
  }
  100% {
    transform: translate(0, 0) scale(1);
  }
}

/* //////////////////// */

@keyframes spin-up-cw {
  0% {
    transform-origin: 400% 400%;
    opacity: 0;
    transform: rotate(-360deg);
  }
  25% {
    opacity: 1;
  }
  100% {
    transform-origin: center center;
    transform: rotate(0);
  }
}

@keyframes spin-up-ccw {
  0% {
    transform-origin: 400% 400%;
    opacity: 0;
    transform: rotate(360deg);
  }
  25% {
    opacity: 1;
  }
  100% {
    transform-origin: center center;
    transform: rotate(0);
  }
}

@keyframes spin-down-cw {
  0% {
    transform-origin: -400% -400%;
    opacity: 0;
    transform: rotate(-360deg);
  }
  25% {
    opacity: 1;
  }
  100% {
    transform-origin: center center;
    transform: rotate(0);
  }
}

@keyframes spin-down-ccw {
  0% {
    transform-origin: -400% -400%;
    opacity: 0;
    transform: rotate(360deg);
  }
  25% {
    opacity: 1;
  }
  100% {
    transform-origin: center center;
    transform: rotate(0);
  }
}

/* //////////////////// */

@keyframes swarm-cw {
  0% {
    transform: translate(-200%, -200%) scale(0) rotate(-360deg);
  }
  10% {
    transform: translate(200%, -200%) scale(.05) rotate(-360deg);
  }
  20% {
    transform: translate(200%, 200%) scale(.1) rotate(-320deg);
  }
  30% {
    transform: translate(-200%, 200%) scale(.15) rotate(-280deg);
  }
  40% {
    transform: translate(-200%, -100%) scale(.2) rotate(-240deg);
  }
  50% {
    transform: translate(100%, -100%) scale(.25) rotate(-200deg);
  }
  60% {
    transform: translate(100%, 100%) scale(.3) rotate(-160deg);
  }
  70% {
    transform: translate(-100%, 100%) scale(.45) rotate(-120deg);
  }
  80% {
    transform: translate(-100%, 0) scale(.6) rotate(-80deg);
  }
  90% {
    transform: translate(0, 0) scale(.8) rotate(-40deg);
  }
  100% {
    transform: translate(0, 0) scale(1) rotate(0);
  }
}

@keyframes swarm-ccw {
  0% {
    transform: translate(200%, 200%) scale(0) rotate(360deg);
  }
  10% {
    transform: translate(200%, -200%) scale(.05) rotate(360deg);
  }
  20% {
    transform: translate(-200%, -200%) scale(.1) rotate(320deg);
  }
  30% {
    transform: translate(-200%, 200%) scale(.15) rotate(280deg);
  }
  40% {
    transform: translate(100%, 200%) scale(.2) rotate(240deg);
  }
  50% {
    transform: translate(100%, -100%) scale(.25) rotate(200deg);
  }
  60% {
    transform: translate(-100%, -100%) scale(.35) rotate(160deg);
  }
  70% {
    transform: translate(-100%, 100%) scale(.45) rotate(120deg);
  }
  80% {
    transform: translate(0, 100%) scale(.6) rotate(80deg);
  }
  90% {
    transform: translate(0, 0) scale(.8) rotate(40deg);
  }
  100% {
    transform: translate(0, 0) scale(1) rotate(0);
  }
}

/* //////////////////// */

@keyframes blur {
  0% {
    opacity: 0;
    filter: blur(20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    filter: blur(0);
  }
}

/* //////////////////// */

@keyframes bubble {
  0% {
    transform: scale(0);
    border-radius: 100%;
  }
  50% {
    transform: scale(2);
    border-radius: 100%;
  }
  100% {
    transform: scale(1);
    border-radius: 0;
  }
}

/* //////////////////// */

@keyframes tumble-right {
  0% {
    opacity: 0;
    transform: translate(-200%, 0) rotate(-180deg);
  }
  25% {
    opacity: 1;
    transform: translate(-150%, 50%) rotate(-135deg);
  }
  50% {
    transform: translate(-100%, 0) rotate(-90deg);
  }
  75% {
    transform: translate(-50%, 25%) rotate(-45deg);
  }
  100% {
    transform: translate(0) rotate(0);
  }
}

@keyframes tumble-left {
  0% {
    opacity: 0;
    transform: translate(200%, 0) rotate(180deg);
  }
  25% {
    opacity: 1;
    transform: translate(150%, 50%) rotate(135deg);
  }
  50% {
    transform: translate(100%, 0) rotate(90deg);
  }
  75% {
    transform: translate(50%, 25%) rotate(45deg);
  }
  100% {
    transform: translate(0) rotate(0);
  }
}

/* //////////////////// */

@keyframes hinge-top {
  0% {
    transform-origin: top center;
    opacity: 0;
    transform: perspective(80px) rotateX(90deg);
  }
  25% {
    opacity: 1;
  }
  75% {
    transform: perspective(80px) rotateX(-45deg);
  }
  100% {
    transform-origin: top center;
    transform: perspective(80px) rotateX(0);
  }
}

@keyframes hinge-bottom {
  0% {
    transform-origin: bottom center;
    opacity: 0;
    transform: perspective(80px) rotateX(-90deg);
  }
  25% {
    opacity: 1;
  }
  75% {
    transform: perspective(80px) rotateX(45deg);
  }
  100% {
    transform-origin: bottom center;
    transform: perspective(80px) rotateX(0);
  }
}

@keyframes hinge-left {
  0% {
    transform-origin: center left;
    opacity: 0;
    transform: perspective(80px) rotateY(-90deg);
  }
  25% {
    opacity: 1;
  }
  75% {
    transform: perspective(80px) rotateY(45deg);
  }
  100% {
    transform-origin: center left;
    transform: perspective(80px) rotateY(0);
  }
}

@keyframes hinge-right {
  0% {
    transform-origin: center right;
    opacity: 0;
    transform: perspective(80px) rotateY(90deg);
  }
  25% {
    opacity: 1;
  }
  75% {
    transform: perspective(80px) rotateY(-45deg);
  }
  100% {
    transform-origin: center right;
    transform: perspective(80px) rotateY(0);
  }
}

/* //////////////////// */

@keyframes carousel-top {
  0% {
    transform-origin: top center;
    opacity: 0;
    transform: perspective(80px) rotateX(90deg) translateZ(80px);
  }
  25% {
    opacity: 1;
  }
  100% {
    transform-origin: top center;
    transform: perspective(80px) rotateX(0) translateZ(0);
  }
}

@keyframes carousel-bottom {
  0% {
    transform-origin: bottom center;
    opacity: 0;
    transform: perspective(80px) rotateX(-90deg) translateZ(80px);
  }
  25% {
    opacity: 1;
  }
  100% {
    transform-origin: bottom center;
    transform: perspective(80px) rotateX(0) translateZ(0);
  }
}

@keyframes carousel-left {
  0% {
    transform-origin: center left;
    opacity: 0;
    transform: perspective(80px) rotateY(-90deg) translateZ(80px);
  }
  25% {
    opacity: 1;
  }
  100% {
    transform-origin: center left;
    transform: perspective(80px) rotateY(0) translateZ(0);
  }
}

@keyframes carousel-right {
  0% {
    transform-origin: center right;
    opacity: 0;
    transform: perspective(80px) rotateY(90deg) translateZ(80px);
  }
  25% {
    opacity: 1;
  }
  100% {
    transform-origin: center right;
    transform: perspective(80px) rotateY(0) translateZ(0);
  }
}

/* //////////////////// */

@keyframes encircle-cw {
  0% {
    transform-origin: top left;
    transform: scale(0);
  }
  33% {
    transform-origin: top right;
  }
  66% {
    transform-origin: bottom right;
  }
  100% {
    transform-origin: bottom left;
    transform: scale(1);
  }
}

@keyframes encircle-ccw {
  0% {
    transform-origin: bottom right;
    transform: scale(0);
  }
  33% {
    transform-origin: top right;
  }
  66% {
    transform-origin: top left;
  }
  100% {
    transform-origin: bottom left;
    transform: scale(1);
  }
}
