.api-docs {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.api-docs .section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.api-docs .section:not(:first-child) {
  margin-top: 48px;
}

.api-docs .section > .title {
  padding-bottom: 8px;
  margin-bottom: 8px;
  width: 100%;
  border-bottom: 1px solid var(--clr-primary-bkg);
}

.api-docs .column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  padding: var(--padding-body);
  overflow-y: auto;
}

.api-docs .preview {
  white-space: pre;
  font-size: var(--txt-size-large);
  min-width: 256px;
  padding-right: calc(var(--padding-body) * 2);
}

.api-docs .preview .item .title.current {
  color: var(--clr-primary-highlight);
}

.api-docs .extended {
  flex-grow: 1;
}

.api-docs .extended .item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 720px;
}

.api-docs .extended .item .info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--clr-panel-bkg);
  box-shadow: var(--shd-soft);
  border-left: 4px solid var(--clr-primary-bkg);
  padding: 16px;
  padding-left: 20px;
  margin-top: 16px;
  width: 100%;
  opacity: 0.5;
}

.api-docs .extended .section > .item .info {
  margin-top: 0;
}

.api-docs .extended .item .info.current {
  border-color: var(--clr-primary-text);
  opacity: 1;
  z-index: 2;
}

.api-docs .extended .item .info:not(.current) {
  cursor: pointer;
}

.api-docs .extended .item .info .code-box {
  background-color: var(--clr-topmost-bkg);
  box-shadow: var(--shd-soft);
  font-size: var(--txt-size-medium);
  padding: 8px 16px;
  margin-bottom: 24px;
}

.api-docs .extended .item .info .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: var(--txt-size-large);
  width: 100%;
}

.api-docs .extended .item .info.current .title {
  margin-bottom: 16px;
}

.api-docs .extended .item .info:not(.current) .title {
  pointer-events: none;
}

.api-docs .extended .item .info .title .flex-space {
  min-width: 16px;
}

.api-docs .extended .item .info .title b {
  color: white;
  font-weight: 600;
}

.api-docs .extended .item .info .desc {
  display: flex;
  flex-direction: column;
  font-size: var(--txt-size-medium);
}

.api-docs .extended .item .info .desc .line:not(:first-child) {
  margin-top: 8px;
}

.api-docs .extended .item .info .desc .opts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -8px;
  margin-top: 8px;
  width: 100%;
}

.api-docs .extended .item .info .desc .opts .opt {
  background-color: var(--clr-page-bkg);
  box-shadow: var(--shd-soft);
  font-size: var(--txt-size-medium);
  padding: 8px 16px;
  margin: 8px;
}
