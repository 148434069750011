.animated-div-selector {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-grow: 1;
  height: 100%;
  padding: var(--padding-body);
  overflow-y: auto;
}

.animated-div-selector .labeled-animated-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  font-size: var(--txt-size-small);
  cursor: pointer;
  animation: fade-in var(--anim-duration) both;
}

.animated-div-selector .labeled-animated-div.selected:hover {
  background-color: var(--clr-secondary-bkg);
  box-shadow: var(--shd-medium);
  margin: -32px;
  z-index: 1;
}

.animated-div-selector .labeled-animated-div .item {
  color: var(--clr-primary-text);
  width: 192px;
  height: 192px;
  overflow: hidden;
}

.animated-div-selector .labeled-animated-div.selected:hover .item {
  color: var(--clr-secondary-text);
  width: 256px;
  height: 256px;
  overflow: visible;
}

.animated-div-selector .labeled-animated-div .label {
  text-align: center;
  text-transform: uppercase;
  overflow-wrap: break-word;
  margin-top: 16px;
  width: 192px;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
