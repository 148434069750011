.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  line-height: 1.75;
  overflow: hidden;
  background-color: var(--clr-page-bkg);
  color: var(--clr-page-text);
}

.body {
  box-shadow: var(--shd-soft);
  width: 100%;
  flex-grow: 1;
  z-index: 2;
  overflow: hidden;
  transition: all var(--anim-duration);
}
