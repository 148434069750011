/* PrismJS 1.15.0
https://prismjs.com/download.html#themes=prism-funky&languages=markup+css+clike+javascript */
/**
 * prism.js Funky theme
 * Based on “Polyfilling the gaps” talk slides http://lea.verou.me/polyfilling-the-gaps/
 * @author Lea Verou
 */

code[class*="language-"],
pre[class*="language-"] {
	font-family: monospace;
	text-align: left;
	white-space: pre-wrap;
	word-spacing: normal;
	word-break: normal;
	word-wrap: normal;
	line-height: 2;
	tab-size: 2;
	hyphens: none;
}

/* Code blocks */
pre[class*="language-"] {
	padding: 0;
	margin: 0;
	overflow: auto;
}

code[class*="language-"] {
	color: rgb(255, 255, 255);
}

/* Inline code */
:not(pre) > code[class*="language-"] {
	padding: .2em;
	border-radius: .3em;
	box-shadow: none;
	white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
	color: rgb(128, 192, 160);
}

.token.punctuation {
	color: rgb(128, 128, 128);
}

.token.operator {
  color: rgb(255, 255, 192);
}

.namespace {
	opacity: .7;
}

.token.script {
  color: rgb(255, 255, 255);
}

.token.property,
.token.tag {
  color: rgb(96, 192, 255);
}

.token.selector,
.token.attr-name,
.token.builtin {
	color: rgb(192, 224, 255);
}

.token.url {
  color: rgb(128, 255, 255);
}

.token.boolean,
.token.number,
.token.symbol {
	color: rgb(192, 255, 128);
}

.token.attr-value,
.token.string,
.token.char {
  color: rgb(255, 192, 128);
}

.token.entity,
.token.constant,
.token.variable,
.token.inserted {
	color: rgb(224, 192, 255);
}

.token.function {
	color: rgb(255, 192, 224);
}

.token.atrule,
.token.keyword {
	color: rgb(255, 128, 192);
}

.token.regex,
.token.important {
	color: rgb(255, 255, 128);
}

.token.important,
.token.bold {
	font-weight: bold;
}

.token.italic {
	font-style: italic;
}

.token.entity {
	cursor: help;
}

.token.deleted {
	color: red;
}
