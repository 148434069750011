.animated-div-customizer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: flex-start;
  flex-grow: 1;
  height: 100%;
}

.animated-div-customizer .preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 100%;
  padding: var(--padding-body);
  overflow-y: auto;
}

.animated-div-customizer .preview .animation {
  color: var(--clr-primary-text);
  width: 256px;
  min-width: 256px;
  height: 256px;
  min-height: 256px;
}

.animated-div-customizer .settings-panel-wrapper {
  z-index: 101;
  height: 100%;
  padding: var(--padding-body);
  overflow-y: auto;
}

.animated-div-customizer .settings-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  align-self: flex-start;
  background-color: var(--clr-panel-bkg);
  box-shadow: var(--shd-soft);
  padding: 24px;
  width: 256px;
}

.animated-div-customizer .settings-panel .input-with-label {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
}

.animated-div-customizer .settings-panel .multi-input {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  width: 100%;
}

.animated-div-customizer .settings-panel .checkboxes-div {
  margin-top: 16px;
  width: 100%;
}

.animated-div-customizer .settings-panel .checkbox-div {
  margin-bottom: 8px;
}

.animated-div-customizer .settings-panel .multiplier {
  margin: 0 8px;
}

.animated-div-customizer .settings-panel .animated-button {
  margin-top: 32px;
}

.animated-div-customizer .settings-panel .animated-button .icon {
  margin-right: 8px;
}

.code-preview {
  max-width: 80vw;
  max-height: 80vh;
  overflow-y: auto;
}
