.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 32px;
  background-color: var(--clr-panel-bkg);
  color: var(--clr-panel-text);
  transition: height var(--anim-duration);
}

.header.small {
  height: calc(48px + 1.5em);
}

.header.large {
  height: 100vh; /* calc(100vh - 48px - 21px); /* 48px padding and 21px span */
  min-height: fit-content;
  overflow-y: auto;
}

.header .menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.header .menu .menu-item {
  font-size: var(--txt-size-large);
  margin-left: 32px;
}

.header .menu .menu-item button {
  text-transform: uppercase;
}

.header.large .menu .flex-space,
.header.large .menu .menu-item {
  display: none;
}

.header .menu .title-and-subtitle {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.header .menu .subtitle {
  position: absolute;
  bottom: 0;
  z-index: 1;
  transition: all var(--anim-duration);
}

.header.small .menu .title {
  font-size: 32px;
}

.header.large .menu .title {
  font-size: 128px;
}

.header.small .menu .subtitle {
  font-size: 7px;
}

.header.large .menu .subtitle {
  font-size: 28px;
}

.header .menu .title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all var(--anim-duration);
}

.header .menu .title .chars {
  z-index: 1;
}

.header .menu .title .space {
  position: relative;
  margin: 0.05em;
  width: 1.5em;
  height: 1.5em;
}

.header .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.header.small .content {
  display: none;
}

.header .content .desc {
  text-align: center;
  font-size: var(--txt-size-large);
  margin: var(--padding-body) 0;
  padding: var(--padding-body) 0;
  max-width: 560px;
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(
      to right,
      transparent,
      var(--clr-page-text),
      transparent
    )
    1;
}

.header .content .buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-size: initial;
}

.header .content .buttons .animated-button {
  margin: 8px;
  width: 120px;
}

.header .content .buttons .animated-button .icon {
  font-size: calc(var(--txt-size-extra) * 2);
  margin: 4px;
  margin-bottom: 12px;
}
