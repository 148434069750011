.animated-div {
  position: relative;
  width: 100%;
  height: 100%;
}

.animated-div.fit-children {
  width: auto;
  height: auto;
}

.animated-div .desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: var(--text-size-small);
  color: red;
}

.animated-div .cells {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  pointer-events: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.animated-div .cells .cell.origin {
  filter: invert(1);
}
