.tutorial {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: var(--padding-body);
  overflow-y: auto;
}

.tutorial .title {
  font-size: var(--txt-size-extra);
  text-align: center;
  text-transform: uppercase;
}

.tutorial .subtitle {
  font-size: var(--txt-size-medium);
  text-align: center;
  margin-top: 8px;
}

.tutorial .steps {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 16px 0 32px 0;
}

.tutorial .steps .step-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.tutorial .steps .step {
  display: flex;
  flex-direction: row;
  background-color: var(--clr-panel-bkg);
  box-shadow: var(--shd-soft);
  padding: 16px;
  margin: 8px;
  width: 360px;
  max-width: 360px;
  flex: 0 0 auto;
}

.tutorial .steps .step.full {
  width: 100%;
  max-width: calc(360px * 3 + 16px * 2);
  flex-grow: 1;
}

.tutorial .steps .step .info {
  display: flex;
  flex-direction: column;
}

.tutorial .steps .step .info .title {
  font-size: var(--txt-size-large);
  text-align: unset;
  margin-bottom: 8px;
}

.tutorial .steps .step .info .desc {
  display: flex;
  flex-direction: column;
  font-size: var(--txt-size-medium);
}

.tutorial .steps .step .desc .line:not(:first-child) {
  margin-top: 8px;
}

.tutorial .steps .step .info code {
  font-size: var(--txt-size-medium);
}

.tutorial .steps .step .image {
  color: var(--clr-primary-text);
  margin-right: 16px;
  width: 96px;
  height: 96px;
  flex: 0 0 auto;
}

.tutorial .buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: initial;
  margin-top: 32px;
}

.tutorial .buttons .animated-button:not(:last-child) {
  margin-right: 16px;
}

.tutorial .buttons .animated-button .icon {
  margin-right: 8px;
}
